// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import './fonts';
@import 'material-icons/iconfont/material-icons.scss';
@import './variables';
// Define the Genesys Branding theme object.
$phoenix-okta-client-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: mat.$azure-palette,
            tertiary: mat.$blue-palette,
        ),
        typography: (
            brand-family: $brand-font-family,
            bold-weight: 400,
        )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    @include mat.all-component-themes($phoenix-okta-client-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($phoenix-okta-client-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($phoenix-okta-client-theme);
/* You can add global styles to this file, and also import other style files */
html {
    box-sizing: border-box;
    font-size: 18px;
    line-height: 24px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    margin: 0;
    padding: 0;
}

a {
    color: $gen-red; /* New branding primary */
    text-decoration: none;
    font-weight: 700;
}

a:hover {
    text-decoration: underline;
}

.wrapper {
    margin: 0 auto;
    padding: 0 50px;
    max-width: 1260px;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    background: $gen-navy; /* Brand Navy */
    font-family: $primary-font-stack;
    font-weight: 300;
    color: $gen-primary-nimbus; /* Nimbus White */
}

/* Typography */
h2 {
    font-size: 3rem;
    line-height: 1.1862em;
    margin-bottom: 10px;
    font-weight: 100;
}

h3 {
    font-size: 1.6667em;
    line-height: 1.3333em;
    margin-bottom: 40px;
    font-weight: 100;
}

h4 {
    font-size: 1.2rem;
    line-height: 0.5em;
}
/* Globals */
/* Global UI override css */
.margin-bottom-small {
    margin-bottom: 1rem;
}

.margin-bottom-medium {
    margin-bottom: 1.5rem;
}

.margin-bottom-large {
    margin-bottom: 2rem;
}

.margin-bottom-xlarge {
    margin-bottom: 4rem;
}

.margin-top-large {
    margin-top: 2rem;
}

.center-text {
    text-align: center;
}

.xlarge-font {
    font-size: x-large;
}

/* Layout */
.about {
    line-height: 1.5rem;
}

.about,
.form-content {
    width: 45%;
}

.content .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

li {
    margin-left: 1em;
}

/* Start Loader */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

.loading-text {
    margin-top: 10px;
    font-size: 1em;
    color: $gen-charcoal;
}
/* End Loader */

@media screen and (max-width: 769px) {
    .about,
    .form-content {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .wrapper,
    .page-header .wrapper {
        padding-left: 5%;
        padding-right: 5%;
    }
}
