/* Due to GDPR issues, webfonts must be localized to the application or Genesys approved service and should
not directly call webfonts via fonts.googleapis.com or fonts.gstatic.com.
Fonts can be localized for custom css and downloaded from here.
See https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=latin */

/* Genesys Primary Branding Font - Roboto */
/* roboto-100 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('/assets/fonts/roboto/roboto-v29-latin-100.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-100.woff2') format('woff2');
}

/* roboto-100italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
        url('/assets/fonts/roboto/roboto-v29-latin-100-italic.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-100-italic.woff2') format('woff2');
}

/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-Light'),
        url('/assets/fonts/roboto/roboto-v29-latin-300.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-300.woff2') format('woff2');
}

/* roboto-300italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-LightItalic'),
        url('/assets/fonts/roboto/roboto-v29-latin-300-italic.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-300-italic.woff2') format('woff2');
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url('/assets/fonts/roboto/roboto-v29-latin-regular.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-regular.woff2') format('woff2');
}

/* roboto-italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), local('Roboto-Italic'),
        url('/assets/fonts/roboto/roboto-v29-latin-italic.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-italic.woff2') format('woff2');
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('/assets/fonts/roboto/roboto-v29-latin-700.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-700.woff2') format('woff2');
}

/* roboto-700italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-BoldItalic'),
        url('/assets/fonts/roboto/roboto-v29-latin-700italic.woff') format('woff'),
        url('/assets/fonts/roboto/roboto-v29-latin-700italic.woff2') format('woff2');
}
