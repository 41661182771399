// Primary Brand Colors
$gen-red: #FF451A;
$gen-navy: #152550;
$gen-azure: #2243A2;
$gen-arctic: #B1CADA;

// Neutral Colors
$gen-charcoal: #4e5054;
$gen-white: #ffffff;
$gen-light-gray: #E8E9ED;
$gen-med-gray: #959699;
$gen-light-orange-tin: #FFECE8;
$gen-green-patina-tint: #BAEFE5;
$gen-primary-nimbus: #F9F8F5;
$gen-light-nimbus: #F1EFEA;

// Ancillary or Secondary Brand Colors
$gen-patina: #18CAA8;
$gen-amber: #F7AD00;

// Tints and Shades
$gen-orange-shade: #CC3715;
$gen-green-patina-shade: #0C6554;
$gen-dark-azure: #0B1636;
$gen-light-azure: #BDC7E3;

// Fonts
$primary-font-stack: Roboto, Arial, Helvetica, sans-serif;
$brand-font-family: Roboto; 

//GKN color palette
$gen-gray-shadow: #00000026;
$gen-black: #0b1228;
$gen-dark-gray: #b9bdca;
$gen-deep-gray: #f1efea;
$gen-border-utilities-focused: #6c82c1;
$gen-border-neutral: #0b122833;
$gen-border-neutral-hovered:#0B122899;
$gen-background-brand-primary-hovered:#d43a16;
$gen-background-brand-primary-pressed:#aa2e11;
$gen-background-brand-primary-default:#ff451a;
$gen-secondary-gray-3: #ababab;
